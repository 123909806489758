(function () {
	'use strict';

	angular
		.module('app')
		.factory('filemakerServer', [
			'filemakerServerConfig',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'manageSettings',
			'utilities',
			'manageFetch',
			filemakerServer,
		]);

	function filemakerServer(
		filemakerServerConfig,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		manageSettings,
		utilities,
		manageFetch
	) {
		var eventEditQueue = {};

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
		};

		function getConfig() {
			return filemakerServerConfig.config();
		}

		function getFieldMap() {
			return filemakerServerConfig.fieldMap();
		}

		function getUnusedMap() {
			return filemakerServerConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return filemakerServerConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return filemakerServerConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return filemakerServerConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function createConnection(schedule) {
			var user = daybackIO.getUser();

			var connection = {
				file: schedule.fileName,
				layout: schedule.layoutName,
				relay: {
					php: schedule.phpRelay,
					server: schedule.serverAddress,
					protocol: schedule.protocol,
					port: schedule.port,
					user: user.email,
				},
				fieldMap: schedule.fieldMap,
			};
			return connection;
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var schedules = [];
			var schedule;
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					//Apply our schedules
					schedule = setSchedule(sources[i]);
					schedules.push(schedule);
				}
			}
			callback(schedules, sourceTemplate);

			function setSchedule(schedule) {
				//Clean schedule data
				schedule.sourceID = schedule.id;

				// Set source specific custom field properties
				if (schedule.customFields) {
					for (var property in schedule.customFields) {
						schedule.customFields[property].mutateInput =
							customFieldMutateInput;
					}
				}

				//Clean schedule data
				calendarIO.cleanSchedule(schedule, sourceTemplate);
				return schedule;
			}
		}

		function changeScheduleName(name, callback, schedule) {
			manageSettings.setCalendarName(name, schedule, callback);
			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			var connection = createConnection(schedule);
			var fieldMap = connection.fieldMap;
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			//Zone offset needs to be applied here so we are sending timestamps for midnight
			var timeStampStart =
				(moment(start).utcOffset(0, true).subtract(1, 'day').valueOf() +
					62135683200000) /
				1000;
			var timeStampEnd =
				(moment(end).utcOffset(0, true).subtract(1, 'day').valueOf() +
					62135683200000) /
				1000;

			//create two objects in a JSON array, each one is a FileMaker Find Request
			var findRequest = {};
			findRequest[fieldMap.start] = '<=' + timeStampEnd;
			findRequest[fieldMap.end] = '>=' + timeStampStart;

			var requests = [findRequest];

			//build query from our array - File, Layout, request
			var query = fmxj.findRecordsURL(
				connection.file,
				connection.layout,
				requests
			);

			//specify FileMaker Server we're posting to
			var relay = connection.relay;

			// Run before events fetched action
			const actionCallbacks = {
				confirm: function () {
					fmxj.postQueryFMS(query, processEvents, null, relay);
				},
				cancel: function () {
					callback(mutateEvents([], schedule, fieldMap));
				},
			};

			const actionResult = calendarIO.runEventActions(
				'beforeEventsFetched',
				{schedule: schedule},
				true,
				actionCallbacks,
				null
			);

			if (!actionResult) {
				return;
			}

			//now do the POST (without any on Download handler);
			fmxj.postQueryFMS(query, processEvents, null, relay);

			function processEvents(result) {
				var events;
				var message;
				//If there is any error except no records found
				if (
					result[0] &&
					result[0].ERRORCODE &&
					result[0].ERRORCODE !== '401'
				) {
					//401 error code is no records found. We don't want to alert when no records are found as that is normal
					message =
						'There was an error retrieving FileMaker events: ' +
						result[0].ERRORCODE +
						' - ' +
						result[0].DESCRIPTION;
					utilities.showMessage(
						message,
						0,
						8000,
						'error',
						null,
						true
					);
				}
				//We have no errors and records were found
				else if (result[0] && !result[0].ERRORCODE) {
					events = mutateEvents(result, schedule, fieldMap);
				}

				//callback is the built in full calendar event callback. This will write the events we just got to the view.
				callback(events);
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = item.split('\n');
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return item.join('\r');
			} else {
				return item;
			}
		}

		function mutateEvents(events, schedule, fieldMap) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}

			// var zoneOffset = moment().zone() * -1;
			var customFields = schedule.customFields;
			var event;
			var propertyValue;
			var output = [];
			for (var i = 0; i < events.length; i++) {
				event = {};
				for (var property in fieldMap) {
					propertyValue = events[i][fieldMap[property]];
					event[property] =
						propertyValue === undefined
							? ''
							: String(propertyValue);
				}
				output.push(mutate(event));
			}

			//Return our result
			return output;

			function mutate(event) {
				var startTime;
				var endTime;

				event.eventSource = schedule.id;
				event.schedule = schedule;

				//Add protection against old calcs that substitute return for \n
				event.title = event.title.replace(/\\n/g, '\n');

				//convert status to array
				event.status =
					event.status === '' ? [] : event.status.split('\n');

				//convert resource to array
				event.resource =
					!event.resource || event.resource === ''
						? []
						: event.resource.split('\n');

				//convert contact to array
				event.contactName =
					!event.contactName || event.contactName === ''
						? []
						: event.contactName.split('\n');
				event.contactID =
					!event.contactID || event.contactID === ''
						? []
						: event.contactID.split('\n');

				//convert project to array
				event.projectName =
					!event.projectName || event.projectName === ''
						? []
						: event.projectName.split('\n');
				event.projectID =
					!event.projectID || event.projectID === ''
						? []
						: event.projectID.split('\n');

				//Convert number to moment
				startTime = moment(
					(event.start - 62135683200) * 1000 + 86400000
				);
				//Convert to utc moment and output date string. Then create a moment from that converted to local time
				event.start = moment(
					moment.utc(startTime).format('YYYY-MM-DD HH:mm:ss')
				);

				//Fix data before returning result
				if (!event.end) {
					event.end = moment.clone(event.start);
				} else {
					//Convert number to moment
					endTime = moment(
						(event.end - 62135683200) * 1000 + 86400000
					);
					//Convert to utc moment and output date string. Then create a moment from that converted to local time
					event.end = moment(
						moment.utc(endTime).format('YYYY-MM-DD HH:mm:ss')
					);
				}

				if (!event.timeStart) {
					//Use string for true here because that is how we get it from our data source. Then change to boolean below.
					event.allDay = 'true';
				}
				event.allDay = event.allDay === 'true' ? true : false;

				// Prep geocode
				if (event.geocode) {
					const geocodeParts = event.geocode.join(',');
					event.geocode = {
						lat: geocodeParts[0]?.trim(),
						lng: geocodeParts[1]?.trim(),
					};
				}

				//return mulated event
				return calendarIO.cleanEvent(event);
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule
		) {
			var connection = createConnection(schedule);
			var fieldMap = connection.fieldMap;
			var source;
			var config = seedcodeCalendar.get('config');
			var customFields = schedule.customFields;
			var allDay = changes.hasOwnProperty('allDay')
				? changes.allDay
				: event.allDay;
			var translatedProperty;
			var editObj = {};

			//Set recid in changes so we know if it is a new event or not
			if (event.recordID) {
				editObj[fieldMap.recordID] = event.recordID;
			}
			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//If the field is mapped and it has a change property, or if it is
				if (
					property !== 'eventID' &&
					property !== 'title' &&
					changes.hasOwnProperty(property)
				) {
					translatedProperty = fieldMap[property];
					if (property === 'start') {
						editObj[fieldMap.dateStart] = moment(
							changes.start
						).format(schedule.fileDateFormat);
						editObj[fieldMap.timeStart] = allDay
							? ''
							: moment(changes.start).format('HH:mm:ss');
					} else if (property === 'end') {
						if (
							(!schedule.unusedMap && fieldMap.dateEnd) ||
							(fieldMap.dateEnd &&
								schedule.unusedMap &&
								!schedule.unusedMap.dateEnd)
						) {
							editObj[fieldMap.dateEnd] = allDay
								? moment(changes.end)
										.subtract(1, 'days')
										.format(schedule.fileDateFormat)
								: moment(changes.end).format(
										schedule.fileDateFormat
									);
						}
						if (
							(!schedule.unusedMap && fieldMap.timeEnd) ||
							(fieldMap.timeEnd &&
								schedule.unusedMap &&
								!schedule.unusedMap.timeEnd)
						) {
							editObj[fieldMap.timeEnd] = allDay
								? ''
								: moment(changes.end).format('HH:mm:ss');
						}
					}
					//Resources need to be called out so we can remove any stored "none" items and make them empty values
					else if (property === 'resource' || property === 'status') {
						editObj[translatedProperty] = changes[property].slice(); //Using slice here to clone the array
						//Remove "none" label from resources so we don't write it back to the source
						for (
							var i = 0;
							i < editObj[translatedProperty].length;
							i++
						) {
							if (
								editObj[translatedProperty][i] ===
								config.noFilterLabel
							) {
								editObj[translatedProperty][i] = '';
							}
						}
						editObj[translatedProperty] =
							editObj[translatedProperty].join('\r');
					} else if (property === 'geocode') {
						editObj[translatedProperty] =
							`${changes[property].lat},${changes[property].lng}`;
					} else if (property === 'tags') {
						editObj[translatedProperty] =
							calendarIO.packageTagsOutput(event[property]);
					} else if (customFields && customFields[property]) {
						editObj[translatedProperty] =
							calendarIO.customFieldOutputTransform(
								property,
								changes[property],
								customFields,
								schedule,
								customFieldMutateOutput
							);
					}
					//Join arrays
					else if (Array.isArray(changes[property])) {
						editObj[translatedProperty] =
							changes[property].join('\r');
					} else {
						editObj[translatedProperty] = changes[property];
					}
				}
			}

			//create query from object
			var query = fmxj.editRecordURL(
				connection.file,
				connection.layout,
				editObj
			);
			//specify FileMaker Server we're posting to
			var relay = connection.relay;

			fmxj.postQueryFMS(query, resultCallback, null, relay); //POST edit query

			function resultCallback(result) {
				var element = seedcodeCalendar.get('element');
				var eventResult;
				var message;

				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				//Check for errors and if we detect one revert the record
				if (result[0] && result[0].ERRORCODE) {
					if (config.passthroughEditErrors) {
						if (callback) {
							callback(null);
						}
					} else if (config.suppressEditEventMessages) {
						dragError();
					} else if (revertFunc) {
						message =
							'There was an error saving the event and your changes will be reverted: ' +
							result[0].ERRORCODE +
							' - ' +
							result[0].DESCRIPTION;
						utilities.showModal(
							'Operation Failed',
							message,
							null,
							null,
							'Revert Changes',
							dragError
						);
					} else if (revertObject) {
						message =
							'There was an error and your event could not be saved: ' +
							result[0].ERRORCODE +
							' - ' +
							result[0].DESCRIPTION;
						utilities.showModal(
							'Operation Failed',
							message,
							'Return To Event',
							cancelError,
							'Revert Changes',
							confirmError
						);
					}
					return;
				}

				//No errors found continue with updating the event with the returned data
				eventResult = mutateEvents(result, schedule, fieldMap)[0];
				eventResult.start = $.fullCalendar.moment(eventResult.start);
				eventResult.end = $.fullCalendar.moment(eventResult.end);

				if (eventResult.allDay) {
					eventResult.end.add(1, 'day');
				}

				//Set all day items so we don't have any conversions
				if (!eventResult.timeStart) {
					eventResult.start.stripTime(); //Use fullcalendar stripTime method
					eventResult.start.stripZone();

					eventResult._start = eventResult.start.clone();

					eventResult.end.stripTime(); //Use fullcalendar stripTime method
					eventResult.end.stripZone();

					eventResult._end = eventResult.end.clone();
				}

				eventResult._allDay = eventResult.allDay;

				var modified = applyEventChanges(event, eventResult);
				if (modified) {
					fullCalendarBridge.update(element, event);
				}

				//Run a callback if one is available
				if (callback) {
					callback(event);
				}
				function dragError() {
					if (revertFunc) {
						revertFunc(null, event, result[0]);
					}
				}
				function confirmError() {
					if (event.eventID) {
						applyEventChanges(event, revertObject);
						calendarIO.assignEventColor(event);
						fullCalendarBridge.update(element, event);
						if (callback) {
							callback(false);
						}
					} else {
						//Remove event from view
						fullCalendarBridge.removeEvents(element, event);
					}
				}
				function cancelError() {
					if (!event.eventStatus) {
						event.eventStatus = {};
					}

					event.eventStatus.revertObject = revertObject;

					if (event.eventStatus.isCustomAction) {
						applyEventChanges(event, revertObject);
					} else {
						event.eventStatus.showPopover = true;
						event.eventStatus.unsavedChanges = true;
						fullCalendarBridge.update(element, event);
						//Reset event status for custom action state
						event.eventStatus.isCustomAction = false;
					}
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var modified;
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (property !== 'eventID') {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (moment.isMoment(changes[property])) {
					if (!changes[property].isSame(event[property])) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule) {
			var connection = createConnection(schedule);
			var query = fmxj.deleteRecordURL(
				connection.file,
				connection.layout,
				event.recordID
			);
			//specify FileMaker Server we're posting to
			var relay = connection.relay;

			fmxj.postQueryFMS(query, processDelete, null, relay); //POST edit query

			function processDelete(result) {
				var message;
				if (result[0].ERRORCODE != 0) {
					message =
						'There was an error deleting the event: ' +
						result[0].ERRORCODE +
						' - ' +
						result[0].DESCRIPTION;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				calendarIO.confirmDelete(event, result[0].ERRORCODE, callback);
			}
			function confirmError() {
				return;
			}
		}
	}
})();
